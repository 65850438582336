import { ArrowRightMiniOutline } from '@coinlocally/cly-core/icons';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { useBreakpoint } from 'shared/hooks';
import { IDashboardConfig } from 'shared/types/dashboard-config.type';
import { SidebarContainer } from './dashboard-layout.style';

const Sidebar: FC<{ data: IDashboardConfig['forex_dashboard'] }> = ({ data }) => {
	const linkRefs = useRef<(HTMLAnchorElement | null)[]>([]);
	const { i18n } = useTranslation('aside');
	const language = i18n.language;
	const isTablet = useBreakpoint('max', 'xl');

	const location = useLocation();

	const [dropDownVisible, setDropDownVisible] = useState<'IB-DASHBOARD' | null>(null);

	return (
		<SidebarContainer className='dashboard_sidebar'>
			<div className={'vertical_view'}>
				<div className='aside_title_container'>
					<h3>{data.title}</h3>
				</div>
				{data.links.map((item, index) =>
					item.sub ? (
						<div className='sub_link_container' key={`side_sub_${index}`}>
							<div
								className='sub_link_header'
								onClick={() => {
									setDropDownVisible(dropDownVisible === 'IB-DASHBOARD' ? null : 'IB-DASHBOARD');
								}}
							>
								{item.icon}
								{item.title}

								<ArrowRightMiniOutline
									className={`drop_icon ${
										dropDownVisible === 'IB-DASHBOARD' || location.pathname.includes('dashboard/ib') ? 'active' : ''
									}`}
								/>
							</div>
							{(dropDownVisible === 'IB-DASHBOARD' || location.pathname.includes('dashboard/ib')) && (
								<div className='sub_links'>
									{item.sub.map((sub, j) => (
										<NavLink
											key={`sub_item_link${index}_${j}`}
											className='sub_item'
											to={`/${language}/${sub.link}`}
											end={true}
											ref={ref => (linkRefs.current[index] = ref)}
										>
											{sub.title}
										</NavLink>
									))}
								</div>
							)}
						</div>
					) : (
						<NavLink
							key={`sidebar_config_${index}`}
							to={`/${language}/${item.link}`}
							end={true}
							className={'vertical_item'}
							ref={ref => (linkRefs.current[index] = ref)}
						>
							{!isTablet && item.icon}
							{item.title}
						</NavLink>
					),
				)}
			</div>
		</SidebarContainer>
	);
};

export default Sidebar;
