import { useMatchMedia } from '@coinlocally/cly-core/hooks';

const breakpoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1218,
	xxl: 1400,
};

export const useBreakpoint = (type: 'min' | 'max', size: keyof typeof breakpoints) => {
	return useMatchMedia(`(${type}-width: ${breakpoints[size]}px)`);
};
