import React, { useState } from 'react';
import { HamburgerContainer, HamburgerItemContainer } from './hamburger-menu.style';
import arrow_left from 'assets/img/navbar/arrow_left.svg';
import download_light from 'assets/img/bottom-download/download_light.svg';
import clyc_logo_light from 'assets/img/bottom-download/clyc_logo_light.svg';
import Menu from 'utils/navbar.config';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import DashboardConfig from 'shared/configs/dashboard.config';
import { FC } from 'react';

const HamburgerItem: FC<{
	title: string;
	link?: string;
	href?: string;
	subMenu: { title: string; link: string }[] | null;
	onOpen: (title: string) => void;
	open: string;
}> = ({ title, link, subMenu, onOpen, open, href }) => {
	const language = useTranslation().i18n.language;
	return (
		<HamburgerItemContainer>
			{!subMenu ? (
				href ? (
					<a href={href} className='simple_title'>
						{title}
					</a>
				) : (
					<Link className='simple_title' to={`/${language}${link?.startsWith('/') ? link : `/${link}`}`}>
						{title}
					</Link>
				)
			) : (
				<>
					<div className={`title_dropdown ${open === title ? 'active' : ''}`} onClick={() => onOpen(open === title ? '' : title)}>
						<span>{title}</span>
						<i className='fal fa-angle-down'></i>
					</div>
					<div className={`sub_menu ${open === title ? 'open' : ''}`}>
						{subMenu.map((item, index) => (
							<Link
								to={`/${language}${item.link.startsWith('/') ? item.link : `/${item.link}`}`}
								key={`${title}_sub_menu_${title}_${index}`}
							>
								{item.title}
							</Link>
						))}
					</div>
				</>
			)}
		</HamburgerItemContainer>
	);
};

const HamburgerMenu: React.FC<{ status: boolean; onClose: () => void; type: 'general' | 'profile' }> = ({ status, onClose, type }) => {
	const { t } = useTranslation('navbar');
	const [innerStatus, setInnerStatus] = useState<boolean>(status);
	const onCloseHandler = () => {
		setInnerStatus(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const menu = Menu();
	const dashboardMenu = DashboardConfig();

	const [opendHamburgerMenuItem, setOpendHamburgerMenuItem] = useState<string>('');
	const language = useTranslation().i18n.language;

	return (
		<HamburgerContainer>
			<div className={`dark_background ${innerStatus ? 'active' : 'deactive'}`} onClick={onCloseHandler}></div>
			<aside className={innerStatus ? 'active' : 'deactive'}>
				{type === 'general' ? (
					<>
						<div className='header'>
							<img src={arrow_left} alt='' className='back' onClick={onCloseHandler} />
							{menu.map((item: any, index: any) => (
								<HamburgerItem
									title={item.title}
									link={item.link}
									href={item.href}
									subMenu={item.subMenu ?? null}
									key={`${item.title}_${index}`}
									open={opendHamburgerMenuItem}
									onOpen={title => setOpendHamburgerMenuItem(title)}
								/>
							))}
						</div>
						<div className='divider'></div>
						<HamburgerItem
							title={t('Try Demo')}
							link='/'
							subMenu={null}
							open={opendHamburgerMenuItem}
							onOpen={title => setOpendHamburgerMenuItem(title)}
						/>

						<Link className='download_container' to={`/${language}/download`}>
							<div className='left'>
								<img src={clyc_logo_light} alt='' />
								<div className='bottom_download_title'>Coinlocally Forex App</div>
								<div className='bottom_download_subtitle'>{t('Trade anytime, anywhere')}</div>
							</div>
							<div className='right'>
								<div className='bottom_download_right_item'>
									<img src={download_light} alt='' />
								</div>
							</div>
						</Link>
					</>
				) : (
					<>
						<div className='header'>
							<img src={arrow_left} alt='' className='back' onClick={onCloseHandler} />
						</div>
						{dashboardMenu.forex_dashboard.links.map((item, index) => (
							<HamburgerItem
								title={item.title}
								link={item.link}
								subMenu={item.sub ?? null}
								key={`${item.title}_${index}`}
								open={opendHamburgerMenuItem}
								onOpen={title => setOpendHamburgerMenuItem(title)}
							/>
						))}
					</>
				)}
			</aside>
		</HamburgerContainer>
	);
};

export default HamburgerMenu;
