import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISecurityStrategies } from 'api/coinlocally/v1';
import { IGetUserProfile } from 'api/coinlocally/v1/users';
import { IProfile } from 'store/types';

const initialState: IProfile = {
	futuresEnabled: localStorage.getItem('futuresEnabled') === 'true' ? true : false || false,
	blvtEnabled: localStorage.getItem('blvtEnabled') === 'true' ? true : false || false,
	questRewardAvailable: localStorage.getItem('questRewardAvailable') === 'true' ? true : false || false,
	topMobileDownloadSkipped: localStorage.getItem('topMobileDownloadSkipped') === 'true' ? true : false || false,
	modalMobileDownloadSkipped: localStorage.getItem('modalMobileDownloadSkipped') === 'true' ? true : false || false,
	tournamentBattleModal: localStorage.getItem('tournamentBattleModal') ? Number(localStorage.getItem('tournamentBattleModal')) : 0,
	isLogin: localStorage.getItem('refresh_auth_token') !== null ? true : false,
	haveChange: false,
	email: '',
	phoneNumber: '',
	questReward: null,
	role: '---',
	haveTicket: 0,
	haveNotife: 0,
	vipLevelName: '',
	bnbBurnForFeesEnabled: false,
	isFreezed: false,
	language: '',
	userName: '',
	avatar: null,
	avatarStatus: null,
	referralType: '',
	ipCountry: 'AM',
	lastLoginActivity: {
		createdAt: 0,
		ipAddress: '',
	},
	securityActions: {
		login: [],
		actions: [],
		notification: [],
	},
	advanceKyc: {
		rejectionReason: null,
		status: null,
	},
	basicKyc: {
		rejectionReason: null,
		status: null,
	},
	corporationKyc: {
		rejectionReason: null,
		status: null,
	},
};

export const Profile = createSlice({
	name: 'userInfo',
	initialState,
	reducers: {
		GetInfo: (state, action: PayloadAction<IGetUserProfile>) => {
			localStorage.setItem('blvtEnabled', action.payload.blvtEnabled ? 'true' : 'false');
			localStorage.setItem('futuresEnabled', action.payload.futuresEnabled ? 'true' : 'false');
			localStorage.setItem('questRewardAvailable', action.payload.questRewardAvailable ? 'true' : 'false');
			localStorage.setItem('profile-lang', action.payload.language);

			state.email = action.payload.email || '';
			state.phoneNumber = action.payload.phoneNumber || '';
			state.role = action.payload.role;
			state.haveTicket = action.payload.adminAnsweredTickets;
			state.vipLevelName = action.payload.vipLevelName;
			state.lastLoginActivity = action.payload.lastLoginActivity;
			state.bnbBurnForFeesEnabled = action.payload.bnbBurnForFeesEnabled;
			state.corporationKyc = action.payload.corporationKyc;
			state.advanceKyc = action.payload.advanceKyc;
			state.basicKyc = action.payload.basicKyc;
			state.futuresEnabled = action.payload.futuresEnabled;
			state.questReward = action.payload.questReward;
			state.userName = action.payload.userName;
			state.isFreezed = action.payload.isFreezed;
			state.referralType = action.payload.referral.type;
			state.language = action.payload.language;
			state.avatar = action.payload.avatar;
			state.avatarStatus = action.payload.avatarStatus;
			state.questRewardAvailable = action.payload.questRewardAvailable;
			state.blvtEnabled = action.payload.blvtEnabled;
		},
		getSecurityStrategies: (state, { payload }: PayloadAction<ISecurityStrategies>) => {
			state.securityActions.actions = payload.actions;
			state.securityActions.login = payload.login;
			state.securityActions.notification = payload.notification;
		},
		IsLogin: (state, { payload }: PayloadAction<boolean>) => {
			state.isLogin = payload;
		},
		changeIPCountry: (state, { payload }: PayloadAction<string>) => {
			state.ipCountry = payload;
		},

		isChanged: (state, action) => {
			state.haveChange = action.payload;
		},
	},
});

export const { GetInfo, IsLogin, changeIPCountry, getSecurityStrategies, isChanged } = Profile.actions;
export default Profile.reducer;
