import { flush, injectGlobal } from '@emotion/css';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { Fragment, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, useLocation, useParams } from 'react-router-dom';
import RoutesJson from 'shared/configs/route-items';
import { getDesignTokens } from 'shared/configs/theme';
import { DashboardLayout, NormalLayout } from 'shared/layout';
import { useAppSelector } from 'shared/hooks';
import { ClyCoreConfig } from '@coinlocally/cly-core';

const RequireAuth: FC<{ children: ReactNode }> = ({ children }) => {
	const isLogin = useAppSelector(state => state.Profile.isLogin);
	const location = useLocation();

	const language = useTranslation().i18n.language;

	return <Fragment>{isLogin ? children : <Navigate to={`/${language}/login?callbackUrl=${location.pathname}`} replace />}</Fragment>;
};

const RequireNoAuth: FC<{ children: ReactNode }> = ({ children }) => {
	const isLogin = useAppSelector(state => state.Profile.isLogin);
	const location = useLocation();

	const { i18n } = useTranslation();
	let language = i18n.language;

	return (
		<Fragment>{!isLogin ? children : <Navigate to={`/${language}/dashboard`} replace state={{ path: location.pathname }} />}</Fragment>
	);
};

export const Router = () => {
	const RoutesJSON = RoutesJson();
	return RoutesJSON.map((item, index) => {
		const Element =
			item.layout.type === 'normal' ? (
				<NormalLayout footerDisplay={item.layout.footerDisplay} navbarType={item.layout.navbarType}>
					<item.component />
				</NormalLayout>
			) : (
				<DashboardLayout footerDisplay={item.layout.footerDisplay} dashboardType={item.layout.dashboardType}>
					<item.component />
				</DashboardLayout>
			);

		return item.authType === 'guardless' ? (
			<Route path={item.route} element={Element} key={'route' + index} />
		) : item.authType === 'auth' ? (
			<Route path={item.route} element={<RequireAuth>{Element}</RequireAuth>} key={'route' + index} />
		) : (
			<Route path={item.route} element={<RequireNoAuth>{Element}</RequireNoAuth>} key={'route' + index} />
		);
	});
};

export const ThemeHandler: FC<{ children: ReactNode }> = ({ children }) => {
	const language = useTranslation().i18n.language;
	const direction = language === 'fa' || language === 'ar' ? 'rtl' : 'ltr';

	const [themeToken, setThemeToken] = useState<Theme>();
	useEffect(() => {
		const token = getDesignTokens('light', direction, language);
		document.dir = direction;
		setThemeToken(createTheme(token));
		document.querySelector('body')!.setAttribute('data-theme', 'light');

		injectGlobal`
			body{
				direction: ${direction};
				overflow-x: hidden;
			}
			* {
				font-family: ${direction === 'rtl' ? 'Vazirmatn' : ''}
			}

			
		`;
		return () => flush();
	}, [language]);

	if (themeToken) {
		return <ThemeProvider theme={themeToken}>{children}</ThemeProvider>;
	}

	return <Outlet />;
};

export const LanguageHandler = () => {
	const { lng } = useParams();
	const { i18n } = useTranslation();
	const location = useLocation();
	let language = i18n.language;
	let languages = ['en', 'fa', 'ru', 'ar', 'de', 'es'];

	if (i18n.language.includes('-')) {
		language = i18n.language.split('-')[0];
	}

	useEffect(() => {
		if (!lng || lng.length !== 2) {
			i18n.changeLanguage('en');
			ClyCoreConfig.LANGUAGE = 'en' as 'en' | 'fa' | 'ru' | 'ar' | 'de' | 'es';
			window.location.replace(language + location.pathname);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (lng && lng.length === 2 && lng !== i18n.language) {
			if (languages.includes(lng)) {
				i18n.changeLanguage(lng);
				ClyCoreConfig.LANGUAGE = lng as 'en' | 'fa' | 'ru' | 'ar' | 'de' | 'es';
			} else {
				window.location.replace(location.pathname.replace(lng, language));
			}
		}
	}, [lng]);

	return <Outlet />;
};
