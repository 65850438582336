const shades = {
	black: '#000000',
	white: '#ffffff',
	gray: {
		bg: {
			800: '#18181A',
			700: '#1C1C1E',
			600: '#272729',
			500: '#38383A',
			400: '#3A3A3C',
			300: '#E1E3E5',
			200: '#F0F0F0',
			100: '#F7F7F7',
		},
		text: {
			800: '#2A2A2C',
			700: '#464649',
			600: '#58585B',
			500: '#939393',
			400: '#BEBEC0',
			300: '#D4D4D5',
		},
	},
	red: {
		900: '#4C2020',
		500: '#E51717',
		300: '#FF6961',
		100: '#FFE5E5',
	},
	green: {
		900: '#1F3C35',
		500: '#009B79',
		300: '#06D6A9',
		100: '#D7F5E3',
	},
	yellow: {
		900: '#322901',
		700: '#E8C431',
		500: '#FCD535',
		100: '#FEF8DE',
	},
	blue: {
		900: '#1A233B',
		500: '#315AE1',
		100: '#EDF1FC',
	},
	orange: {
		500: '#F5A42A',
	},
};

const light_colors = {
	background: {
		black: shades.black,
		primary: shades.black,
		secondary: shades.gray.bg[100],
		popup: shades.white,
		error: shades.red[100],
		success: shades.green[100],
		warning: shades.yellow[100],
		info: shades.blue[100],
		input: {
			primary: shades.gray.bg[100],
		},
		button: {
			primary: shades.yellow[500],
			primary_hovered: shades.yellow[700],
			secondary: shades.gray.bg[200],
			secondary_hovered: shades.gray.bg[300],
			outline_hovered: shades.yellow[100],
			red: shades.red[500],
			green: shades.green[500],
		},
		elevated: {
			secondary: shades.white,
		},
		dashboard: {
			selected: shades.black,
		},
	},
	border: {
		primary: shades.gray.bg[300],
	},
	text: {
		primary: shades.black,
		secondary: shades.gray.text[600],
		tertiary: shades.gray.text[400],
		quaternary: shades.gray.text[300],
		error: shades.red[500],
		success: shades.green[500],
		warning: shades.yellow[900],
		info: shades.blue[500],
		link: shades.orange[500],
		button_label: {
			primary: shades.black,
			secondary: shades.black,
		},
		input: {
			placeholder: shades.gray.text[400],
		},
	},
	brand: shades.yellow[500],
};

const dark_colors = {
	background: {
		black: shades.black,
		primary: shades.gray.bg[800],
		secondary: shades.gray.bg[600],
		popup: shades.gray.bg[700],
		error: shades.red[900],
		success: shades.green[900],
		warning: shades.yellow[900],
		info: shades.blue[900],
		input: {
			primary: shades.gray.bg[400],
		},
		button: {
			primary: shades.yellow[500],
			primary_hovered: shades.yellow[700],
			secondary: shades.gray.bg[400],
			secondary_hovered: shades.gray.bg[600],
			outline_hovered: shades.yellow[900],
			red: shades.red[500],
			green: shades.green[500],
		},
		elevated: {
			secondary: shades.gray.bg[400],
		},
		dashboard: {
			selected: shades.gray.bg[400],
		},
	},
	border: {
		primary: shades.gray.bg[500],
	},
	text: {
		primary: shades.white,
		secondary: shades.gray.text[500],
		tertiary: shades.gray.text[700],
		quaternary: shades.gray.text[800],
		error: shades.red[300],
		success: shades.green[300],
		warning: shades.yellow[500],
		info: shades.blue[500],
		link: shades.orange[500],
		button_label: {
			primary: shades.black,
			secondary: shades.white,
		},
		input: {
			placeholder: shades.gray.text[500],
		},
	},
	brand: shades.yellow[500],
};

export const getDesignTokens = (mode: 'dark' | 'light', dir: 'rtl' | 'ltr', lang: string) => ({
	mode,
	palette: {},
	direction: dir,
	lang,
	colors: {
		shades: shades,
		...(mode === 'light' ? { ...light_colors } : { ...dark_colors }),
	},
});
