import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const NavbarContainer = styled.nav(
	({ theme }) => css`
		position: sticky;
		width: 100%;
		z-index: 90;
		top: 0;
		height: 64px;
		padding: 0 32px;
		border-bottom: 1px solid ${theme.colors.border.primary};

		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #fff;

		.left_field {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 20px;
			width: max-content;
			height: 100%;
		}
		.menu_list {
			display: none;
		}

		.icon_button {
			background: transparent;
			color: ${theme.colors.text.primary};
			cursor: pointer;
			svg {
				width: 20px;
				height: 20px;
			}
		}

		.right_field {
			display: flex;
			align-items: stretch;
			gap: 8px;
			height: 100%;

			.divider {
				width: 1px;
				align-self: center;
				height: 24px;
				margin-inline-end: 8px;
				background-color: ${theme.colors.border.primary};
			}
		}

		.login_container {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		@media (min-width: 999px) {
			.menu_list {
				display: flex;
				align-items: stretch;
				justify-content: space-between;
				width: 100%;
				height: 100%;
				gap: 15px;
			}
		}
	`,
);

export const NavbarItemContainer = styled.div(props => ({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',

	'& .title_dropdown': {
		display: 'flex',
		gap: '6px',
		color: 'black',
		alignItems: 'center',

		span: {
			fontWeight: '400',
			fontSize: '0.875rem',
		},

		i: {
			fontSize: '1.2rem',
			marginTop: '2px',
		},
	},

	'& .simple_title': {
		fontWeight: '400',
		fontSize: '0.875rem',
		color: 'black',
	},

	'& .sub_menu': {
		position: 'absolute',
		zIndex: '1',
		top: '64px',
		left: '0',
		opacity: '0',
		pointerEvents: 'none',
		backgroundColor: 'white',
		padding: '12px',
		borderRadius: '4px',
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 8px 0px',
		width: '225px',
		transition: 'all 0.1s linear 0s',
		maxHeight: 'calc(100vh - 90px)',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		minWidth: '160px',

		a: {
			color: 'black',
			height: '40px',
			display: 'flex',
			alignItems: 'center',
			padding: '0 12px',
			cursor: 'pointer',
			fontWeight: '300',
			fontSize: '0.875rem',
			borderRadius: '4px',

			'&:hover': {
				background: '#F7F7F7',
				fontWeight: '400',
			},
		},
	},

	'&:hover': {
		'& .sub_menu': {
			opacity: '1',
			pointerEvents: 'inherit',
		},
		'& .fa-angle-down': {
			transform: 'rotateX(-180deg)',
			top: '-2px',
		},
	},
}));
