import { Navbar } from 'shared/layout/navbar';
import { Footer } from 'shared/layout/footer';
import { FC } from 'react';
import { NormalLayoutProps } from 'shared/types/layout.type';

const NormalLayout: FC<NormalLayoutProps> = ({ children, footerDisplay = true, navbarType }) => {
	return (
		<>
			<Navbar navbarType={navbarType} />
			{children}
			{footerDisplay && <Footer />}
		</>
	);
};

export default NormalLayout;
