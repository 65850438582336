import { Footer } from 'shared/layout/footer';
import { Navbar } from '../navbar';
import { FC } from 'react';
import DashboardConfig from 'shared/configs/dashboard.config';
import { ContentField, MainField } from './dashboard-layout.style';
import Sidebar from './sidebar';
import type { DashboardLayoutProps } from 'shared/types/layout.type';
import { useBreakpoint } from 'shared/hooks';

const DashboardLayout: FC<DashboardLayoutProps> = ({ children, dashboardType: dashboard, footerDisplay = false }) => {
	const config = DashboardConfig();
	const isTablet = useBreakpoint('max', 'xl');

	return (
		<>
			<Navbar />
			<MainField>
				{isTablet ? null : (
					<div>
						<Sidebar data={config[dashboard]} />
					</div>
				)}
				<ContentField>{children}</ContentField>
			</MainField>
			{footerDisplay && <Footer />}
		</>
	);
};

export default DashboardLayout;
