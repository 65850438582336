class NumberUtils {
	formatPriceWithDecimals(price: string | number, precision?: number) {
		if (+price === 0) {
			return 0;
		}
		if (!price) {
			return null;
		}

		const amount = typeof price === 'string' ? price : price.toString();
		const [integerPart, decimalPart] = amount.split('.');

		if (decimalPart) {
			if (typeof precision === 'number') {
				const decimal = decimalPart.length > precision ? decimalPart.substring(0, precision) : decimalPart;
				return `${Number(integerPart).toLocaleString('en')}.${decimal}`;
			} else {
				return `${Number(integerPart).toLocaleString('en')}.${decimalPart}`;
			}
		}

		return Number(amount).toLocaleString('en');
	}
}

const numberUtils = new NumberUtils();

export default numberUtils;
