/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from 'axios';
import toast from 'react-hot-toast';
import { envResolver } from 'shared/utils';

class RequestHandler extends EventTarget {
	accessTokenKeyName = 'access_auth_token';
	refreshTokenKeyName = 'refresh_auth_token';

	axiosInstance: AxiosInstance = axios.create();
	isQueueProcessing = false;
	queue: { url: string; method: 'get' | 'post' | 'put' | 'delete'; data: any; baseUrl?: string }[] = [];

	constructor() {
		super();
		this.addEventListener('handleRequestsQueue', this.queueRequestsHandler, false);
	}

	clearHeader() {
		localStorage.removeItem(this.accessTokenKeyName);
		localStorage.removeItem(this.refreshTokenKeyName);
	}

	private getPathname() {
		return window.location.pathname;
	}

	async authorizationHandler() {
		let _refreshToken = localStorage.getItem(this.refreshTokenKeyName);
		let _accessToken = localStorage.getItem(this.accessTokenKeyName);
		if (_refreshToken && _accessToken) {
			const refreshToken = JSON.parse(_refreshToken);
			const accessToken = JSON.parse(_accessToken);

			const refreshTokenMaxExpiredTimestamp = new Date(refreshToken.expiredAt).getTime();
			const accessTokenMaxExpiredTimestamp = new Date(accessToken.expiredAt).getTime() - 10000;

			if (refreshTokenMaxExpiredTimestamp <= Date.now()) {
				this.clearHeader();
				const language = localStorage.getItem('i18nextLng') ?? 'en';
				window.location.href = `/${language}/login?callbackUrl=${this.getPathname()}`;
			} else {
				if (accessTokenMaxExpiredTimestamp <= Date.now()) {
					const { data } = await axios.put(`${envResolver.API_BASE_URL}api/v1/users/session`, {
						refreshToken: refreshToken.token,
					});

					localStorage.setItem(
						this.accessTokenKeyName,
						JSON.stringify({
							token: data.result.token,
							expiredAt: data.result.expiredAt,
						}),
					);

					localStorage.setItem(
						this.refreshTokenKeyName,
						JSON.stringify({
							token: data.result.refreshToken,
							expiredAt: data.result.refreshTokenExpiredAt,
						}),
					);
				}
			}
		}
	}

	async queueRequestsHandler() {
		this.isQueueProcessing = true;

		while (this.queue.length > 0) {
			try {
				await this.authorizationHandler();
			} catch (error) {
				this.clearHeader();
				const language = localStorage.getItem('i18nextLng') ?? 'en';
				window.location.href = `/${language}/login?callbackUrl=${this.getPathname()}`;
			}

			const currentIndex = this.queue.length - 1;
			const request = this.queue[currentIndex];

			const tokenKeyName = this.accessTokenKeyName;
			const jwtToken = JSON.parse(localStorage.getItem(tokenKeyName) ?? '{}').token;
			const token = jwtToken ? `Bearer ${jwtToken}` : null;

			const language = localStorage.getItem('i18nextLng') ?? 'en';

			const result = this.axiosInstance(request.url, {
				method: request.method,
				data: request.data,
				...(request.baseUrl && {
					baseURL: request.baseUrl,
				}),
				headers: {
					...(token && {
						Authorization: token,
					}),
					'content-language': language,
				},
			});

			this.dispatchEvent(
				new CustomEvent(`request:${currentIndex}`, {
					detail: result,
				}),
			);

			this.queue.pop();
		}

		this.isQueueProcessing = false;
	}

	async call<T>(options: { url: string; method: 'get' | 'post' | 'put' | 'delete'; data?: any }) {
		return new Promise<T>((resolve, reject) => {
			const mainBaseUrl = envResolver.API_BASE_URL;

			const currentLength = this.queue.push({
				url: options.url,
				method: options.method,
				data: options.data,
				...(!options.url.includes('https://') && {
					baseUrl: mainBaseUrl,
				}),
			});

			if (!this.isQueueProcessing) {
				this.dispatchEvent(new CustomEvent('handleRequestsQueue'));
			}

			this.addEventListener(
				`request:${currentLength - 1}`,
				async (event: any) => {
					try {
						const result = await event.detail;

						if (result.data.message && result.status === 200) {
							toast.success(result.data.message, { style: { zIndex: 2000 } });
						}

						resolve(result.data);
					} catch (error: any) {
						if (!error?.response?.data?.message) {
							if (error.response?.status === 403) {
								toast.error('You are not authorized to execute this request!', { style: { zIndex: 2000 } });
							}
						}
						if (error.response?.data?.code === 3 || error.response?.data?.code === 4 || error.response?.data?.code === 100) {
							this.clearHeader();
							const language = localStorage.getItem('i18nextLng') ?? 'en';
							window.location.href = `/${language}/login?callbackUrl=${this.getPathname()}`;
						} else {
							if (error?.response?.data?.message) {
								toast.error(error?.response?.data?.message, { style: { zIndex: 2000 } });
							}
						}

						reject(error);
					}
				},
				{
					once: true,
				},
			);
		});
	}
}

const requestHandler = new RequestHandler();
export default requestHandler;
