import { useState, type FC } from 'react';
// import { useAppSelector } from 'shared/hooks';
import type { NavbarProps } from 'shared/types/layout.type';
import { useTranslation } from 'react-i18next';
import { NavbarContainer } from './navbar.style';
import Menu from 'utils/navbar.config';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from 'assets/img/logo.svg';
import NavbarItem from './navbar-item';
import { GlobalOutline, MenuOutline } from '@coinlocally/cly-core/icons';
import HamburgerMenu from './hamburger-menu';
import { ChangeLanguageModal, ClyCoreConfig } from '@coinlocally/cly-core';
// import { addCallbackInLogin } from 'shared/utils';
// import Profile from './profile';
import { useBreakpoint } from 'shared/hooks';

const Navbar: FC<NavbarProps> = ({ navbarType = 'FULL' }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [hamburgerStatus, setHamburgerStatus] = useState<'profile' | 'general' | ''>('');
	const [lngModalStatus, setLngModalStatus] = useState<boolean>(false);
	// const isLogin = useAppSelector(store => store.Profile.isLogin);
	const { i18n } = useTranslation('navbar');
	const menu = Menu();
	const isTablet = useBreakpoint('max', 'xl');

	const onChangeLanguageHandler = (lng: string) => {
		if (lng !== i18n.language) {
			ClyCoreConfig.LANGUAGE = lng as 'en' | 'ru' | 'fa' | 'ar' | 'de' | 'es';
			navigate(location.pathname.replace(i18n.language, lng), { replace: true });
			i18n.changeLanguage(lng);
		}
	};

	return (
		<NavbarContainer>
			<div className='left_field'>
				<Link to={`/${i18n.language}`}>
					<img src={Logo} alt='' />
				</Link>

				{navbarType === 'FULL' ? (
					<div className='menu_list'>
						{menu.map((item: any, index: any) => (
							<NavbarItem
								title={item.title}
								subMenu={item.subMenu ?? null}
								link={item.link}
								href={item.href}
								key={`navbar_item_${index}`}
							/>
						))}
					</div>
				) : null}
			</div>

			<div className='right_field'>
				{navbarType === 'FULL' ? (
					<>
						{/* {isLogin ? (
							<>
								{isTablet ? (
									<button className='icon_button' onClick={() => setHamburgerStatus('profile')}>
										<UserOutline />
									</button>
								) : (
									<Profile />
								)}
							</>
						) : (
							<div className='login_container'>
								<Button variant='outline' size='small' to={`/${i18n.language}/login${addCallbackInLogin()}`}>
									{t('login')}
								</Button>
								<Button variant='primary' size='small' to={`/${i18n.language}/register`}>
									{t('register')}
								</Button>
							</div>
						)} */}
						<div className='divider' />
					</>
				) : null}

				{!isTablet ? (
					<button className='icon_button' onClick={() => setLngModalStatus(true)}>
						<GlobalOutline />
					</button>
				) : (
					<button className='icon_button' onClick={() => setHamburgerStatus('general')}>
						<MenuOutline />
					</button>
				)}
			</div>

			{lngModalStatus ? (
				<ChangeLanguageModal status={lngModalStatus} setStatus={setLngModalStatus} onLanguageClick={onChangeLanguageHandler} />
			) : null}

			{hamburgerStatus !== '' ? (
				<HamburgerMenu
					status={['general', 'profile'].includes(hamburgerStatus)}
					onClose={() => setHamburgerStatus('')}
					type={hamburgerStatus}
				/>
			) : null}
		</NavbarContainer>
	);
};

export default Navbar;
