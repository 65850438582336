import { configureStore } from '@reduxjs/toolkit';
import Profile from './reducer/profile';
import BalanceVisibility from './reducer/balance-visibility';
import IBDashboard from './reducer/ib-dashboard';

export const store = configureStore({
	reducer: {
		Profile: Profile,
		BalanceVisibility: BalanceVisibility,
		IBDashboard: IBDashboard,
	},
});
