import packageJSON from '../../../package.json';

const envResolver = {
	API_BASE_URL: process.env.REACT_APP_API_BASE_URL ?? 'https://api.coinlocally.com/',
	GOOGLE_AUTH_CLIENT_ID:
		process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? '1097530902697-r3rmoku9l37gqrn6l4i6u5n9ko4evooa.apps.googleusercontent.com',
	VERSION: packageJSON.version,
};

export { envResolver };
