import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { NavbarItemContainer } from './navbar.style';
import { Link } from 'react-router-dom';

type TSubmenu = {
	title: string;
} & ({ link: string } | { href: string });
const NavbarItem: FC<{
	title: ReactNode;
	link?: string;
	href?: string;
	right?: boolean;
	subMenu: TSubmenu[] | null;
}> = ({ title, link, subMenu, href, right = false }) => {
	const language = useTranslation().i18n.language;

	return (
		<NavbarItemContainer>
			{!subMenu ? (
				href ? (
					<a href={href} className='simple_title'>
						{title}
					</a>
				) : (
					<Link className='simple_title' to={`/${language}${link}`}>
						{title}
					</Link>
				)
			) : (
				<>
					<div className={`title_dropdown`}>
						<span>{title}</span>
						<i className='fal fa-angle-down'></i>
					</div>
					<div className={`sub_menu`}>
						{subMenu.map((item, index) =>
							'link' in item ? (
								<Link to={`/${language}${item.link}`} key={`nav_${title}_sub_menu_${title}_${index}`}>
									{item.title}
								</Link>
							) : (
								<a href={item.href} key={`nav_${title}_sub_menu_${title}_${index}`}>
									{item.title}
								</a>
							),
						)}
					</div>
				</>
			)}
		</NavbarItemContainer>
	);
};

export default NavbarItem;
