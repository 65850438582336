import { createSlice } from '@reduxjs/toolkit';
import { IBalanceVisibility } from 'store/types';

const initialState: IBalanceVisibility = {
	affiliateDashboard: localStorage.getItem('balanceVisibility')
		? JSON.parse(localStorage.getItem('balanceVisibility')!).affiliateDashboard
			? true
			: false
		: true,
	ibDashboard: localStorage.getItem('balanceVisibility')
		? JSON.parse(localStorage.getItem('balanceVisibility')!).ibDashboard
			? true
			: false
		: true,
	forex: localStorage.getItem('balanceVisibility') ? (JSON.parse(localStorage.getItem('balanceVisibility')!).forex ? true : false) : true,
	deposit: localStorage.getItem('balanceVisibility')
		? JSON.parse(localStorage.getItem('balanceVisibility')!).deposit
			? true
			: false
		: true,
};

export const BalanceVisibility = createSlice({
	name: 'balanceVisibility',
	initialState: initialState,
	reducers: {
		changeBalanceVisibility: (state, action: { payload: { status: boolean; visibilityScope: keyof IBalanceVisibility } }) => {
			let updatingCacheData = localStorage.getItem('balanceVisibility') ? JSON.parse(localStorage.getItem('balanceVisibility')!) : {};

			updatingCacheData[action.payload.visibilityScope] = action.payload.status;
			localStorage.setItem('balanceVisibility', JSON.stringify(updatingCacheData));
			state[action.payload.visibilityScope] = action.payload.status;
		},
	},
});

export const { changeBalanceVisibility } = BalanceVisibility.actions;
export default BalanceVisibility.reducer;
