import ReactDOM from 'react-dom/client';
import './global.css';
import './assets/css/general/fontAwsome.css';
import App from './App';
import './i18n';
import { Provider } from 'react-redux';
import { store } from 'store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { envResolver } from 'shared/utils';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<GoogleOAuthProvider clientId={envResolver.GOOGLE_AUTH_CLIENT_ID}>
		<Provider store={store}>
			<App />
		</Provider>
	</GoogleOAuthProvider>,
);
