import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'cly-uikit/css/font/oswald.min.css';
import 'cly-uikit/css/font/product-sans.min.css';
import 'cly-uikit/css/global.min.css';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LanguageHandler, Router, ThemeHandler } from 'shared/helper/app-helper';

import Loader from './components/layout/loader';
import { Toaster } from 'react-hot-toast';

function App() {
	return (
		<ThemeHandler>
			<BrowserRouter>
				<Suspense fallback={<Loader />}>
					<Toaster
						position='bottom-right'
						containerStyle={{
							zIndex: 9999999999,
						}}
					/>
					<Routes>
						<Route path='' element={<LanguageHandler />} />

						<Route path=':lng' element={<LanguageHandler />}>
							{Router()}
						</Route>
					</Routes>
				</Suspense>
			</BrowserRouter>
		</ThemeHandler>
	);
}

export default App;
